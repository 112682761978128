




























































































import { Vue, Component, Watch } from 'vue-property-decorator'
import SheetViewer from './components/SheetViewer.vue'
import TaskAndData from './components/TaskAndData.vue'
import FilterGroup from '@/components/filterGroup'
import { CourseController, DropDownController } from '@/services/request.service'
import { clearEmptyArray } from '@/utils/utils'

@Component({
  components: {
    SheetViewer,
    TaskAndData,
    FilterGroup,
  },
})
export default class Teaching extends Vue {
  private type = 'subjectClass'
  private filters: any = {
    schoolYearId: 0,
    courseId: [],
  }
  private currentTab = 'task'
  private mode = 'view'
  private courses: Array<any> = []
  private schoolYears: Array<any> = []
  private currentCourseFlag = false

  private get isChildRoute(): boolean {
    return this.$route.name !== 'teaching'
  }

  private get menuAuth(): any {
    return this.$store.state.userAuths || {}
  }

  private get locale(): any {
    return this.$store.state.locale
  }

  private get menuList(): Array<any> {
    return [
      (this.menuAuth.courseTeacher || this.menuAuth.courseManager) && {
        key: 'subjectClass',
        label: this.$t('common.subjectClass'),
      },
      this.menuAuth.higSchoolCcaTeacher && {
        key: 'CCAClass',
        label: this.$t('router.CCAClass'),
      },
    ].filter(item => item)
  }

  private get schoolYear(): any {
    return this.schoolYears.find(item => item.key === this.filters.schoolYearId)
  }

  private get gradebookDisabled(): boolean {
    return (
      this.type === 'CCAClass' &&
      !this.courses.find(item => item.key === this.filters.courseId)?.extraValue
    )
  }
  private updateData() {
    // this.$forceUpdate()
    this.$router.go(0)
  }
  @Watch('$route', { immediate: true })
  private onRouteChange(newVal): void {
    if (!this.isChildRoute) {
      if (newVal.query.type) {
        this.type = newVal.query.type
      }
      this.getSchoolYears()
    }
  }

  private changeType({ item, key, keyPath }): void {
    this.filters.courseId = key === 'subjectClass' ? [] : undefined
    this.$router.replace({ name: 'teaching', query: { type: key } })
    this.currentTab = 'task'
  }

  private changeTab(): void {
    if (this.currentTab === 'task') {
      this.getTaskData()
    } else {
      this.getGradebookData()
    }
  }

  private filterData(inputValue, path) {
    return path.some(option => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
  }

  private getSchoolYears(): void {
    DropDownController.getSchoolYearRuleList().then(res => {
      this.schoolYears = res.data
      this.filters.schoolYearId = res.data[0].key
      if (this.filters.schoolYearId) {
        this.getCourses()
      }
    })
  }

  private getCourses(): void {
    if (!this.filters.schoolYearId) return
    const type = this.type
    if (this.type === 'subjectClass') {
      CourseController.getCoursesCascadeBySchoolYear(this.filters.schoolYearId)
        .then(res => {
          if (this.type !== type) return
          this.courses = clearEmptyArray(res.data)
          if (!res.data[0]) {
            this.$set(this.filters, 'courseId', [])
          }
          if (this.$route.query.id) {
            const id = parseInt(this.$route.query.id.toString(), 10)
            const section = res.data.find(item => item.subOptions.some(option => option.key === id))
            this.$set(this.filters, 'courseId', section ? [section.key, id] : [])
          } else {
            if (res.data[0] && !this.filters.courseId.length) {
              this.$set(this.filters, 'courseId', [res.data[0].key, res.data[0].subOptions[0].key])
            }
          }
          this.currentCourseFlag = true
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.currentTab === 'task' ? this.getTaskData() : this.getGradebookData()
        })
    } else {
      this.$set(this.filters, 'courseId', undefined)
      DropDownController.getCourseCcaBySchoolYear(this.filters.schoolYearId)
        .then(res => {
          if (this.type !== type) return
          this.courses = res.data
          if (!res.data[0]) {
            this.$set(this.filters, 'courseId', [])
          }
          if (this.$route.query.id) {
            const id = parseInt(this.$route.query.id.toString(), 10)
            this.$set(this.filters, 'courseId', id)
          } else {
            if (res.data[0]) {
              this.$set(this.filters, 'courseId', res.data[0].key)
            }
          }
          this.currentCourseFlag = res.data[0]?.extraValue
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.currentTab === 'task' ? this.getTaskData() : this.getGradebookData()
        })
    }
  }

  private changeCourse(value): void {
    if (this.type !== 'subjectClass') {
      this.courses.forEach(course => {
        if (course.key === value) {
          this.currentCourseFlag = course.extraValue
        }
      })
    }
    if (this.gradebookDisabled) {
      this.currentTab = 'task'
    }
    this.currentTab === 'task' ? this.getTaskData() : this.getGradebookData()
  }

  private getTaskData(): void {
    this.$nextTick(() => {
      if (this.$refs.task) {
        const task = this.$refs.task as any
        task.getData()
      } else {
        setTimeout(() => this.getTaskData(), 1000)
      }
    })
  }

  private getGradebookData(): void {
    this.$nextTick(() => {
      if (this.$refs.gradebook) {
        const gradebook = this.$refs.gradebook as any
        gradebook.getData()
      } else {
        setTimeout(() => this.getGradebookData(), 1000)
      }
    })
  }
}
