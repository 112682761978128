














































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { CourseController, DropDownController, TaskController } from '@/services/request.service'
import { guid } from '@/utils/utils'
import moment from 'moment'
import Upload from '@/components/Upload.vue'
import { SimditorVue } from '@/components/simditor/Simditor'
Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    Upload,
    SimditorVue,
  },
})
export default class AssignmentEditor extends Vue {
  private scoreAllow: any = true
  private assignmentEditor: any
  private canLeave: boolean = false
  private courses: any = []
  private formItemLayout: any = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
  }
  private courseIds: any = []
  private description: any = null
  private treeSelectCourses: any = []
  private emptyCourse: boolean = false
  private online: String = 'true'
  private loading: boolean = false
  private typeList: any = []
  private uploading = false
  private moment = moment
  private editable: any = true

  private get defaultClassId(): any {
    return this.$route.query.defaultClassId ? Number(this.$route.query.defaultClassId) : undefined
  }
  private get locale(): any {
    return this.$store.state.locale
  }
  private get assignmentId(): any {
    return this.$route.query.id
  }
  private get dateShow(): any {
    return this.$route.query.dateShow
  }
  private get formatSections(): any {
    return this.courseType === 'subjectClass'
      ? this.courses.map(item => {
          return {
            children: item.subOptions.map(child => ({
              key: child.key,
              title: this.locale === 'zh' ? child.value : child.enValue || child.value,
              value: child.key,
            })),
            title: `${item.value}-${item.enValue}`,
            key: `${item.enValue}_${item.extraValue}_${item.key}`,
            value: `${item.enValue}_${item.extraValue}_${item.key}`,
          }
        })
      : []
  }
  private get type(): any {
    return this.$route.query.type
  }
  private get currentSchoolYear(): any {
    return this.$store.state.currentSchoolYear
  }
  private get courseType(): any {
    return this.$route.query.assignType || 'subjectClass'
  }
  private get descriptionErr(): any {
    if (this.description === null) return false
    return this.description === ''
      ? this.$t('tips.enterDescription')
      : this.description.length > 2000
      ? this.$t('tips.textLimit', { num: 2000 })
      : ''
  }
  private set descriptionErr(val) {
    this.description = ''
  }

  private beforeCreate(): void {
    this.assignmentEditor = this.$form.createForm(this)
  }

  private beforeRouteLeave(to, from, next): void {
    if (!this.$store.state.forceBack) {
      if (!this.canLeave) {
        this.$confirm({
          title: this.$t('common.unsaveConfirm') as string,
          onOk: () => {
            next()
          },
          onCancel: () => {
            next(false)
          },
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }

  private cancel(): void {
    this.$router.back()
  }

  private changeType(value): void {
    this.online = value
    if (this.online === 'true') {
      this.assignmentEditor.setFieldsValue({ handInOverDeadline: false })
    }
  }

  private confirm(): void {
    this.loading = true
    this.assignmentEditor.validateFields((err: any, values: any) => {
      let flag = false
      if (!this.courseIds.length && this.type === 'subjectClass') {
        this.emptyCourse = true
        flag = true
      }
      if (!this.description) {
        this.descriptionErr = true
      }
      if (err || flag || this.descriptionErr) {
        this.loading = false
        return
      } else {
        const data = {
          ...values,
          endDate: values.deadline.valueOf(),
          taskId: this.type === 'edit' ? this.$route.query.id : undefined,
          courseIds: this.courseType === 'subjectClass' ? this.courseIds : values.courseIds,
          resourceIds: (values.attachments || []).map(item => item.uid),
          startDate: values.startDate.valueOf(),
          courseType: this.courseType === 'subjectClass' ? '1001' : '1002',
          description: this.description,
        }
        if (this.type === 'add' || this.type === 'copyEdit') {
          TaskController.add(data)
            .then(res => {
              this.$message.success(this.$tc('common.saveSuccess'))
              this.canLeave = true
              this.$route.meta.refresh = true
              this.$router.back()
            })
            .catch(err => {
              console.error(err)
            })
            .finally(() => (this.loading = false))
        } else {
          TaskController.update(data)
            .then(res => {
              this.$message.success(this.$tc('common.saveSuccess'))
              this.canLeave = true
              this.$route.meta.refresh = true
              this.$router.back()
            })
            .catch(err => {
              console.error(err)
            })
            .finally(() => (this.loading = false))
        }
      }
    })
  }

  private created() {
    this.getDropDownInfo()
  }

  private disabledDate(current): any {
    return current && current < moment().startOf('day')
  }

  private disabledStartDate(current): any {
    return (
      current &&
      (current < moment(this.currentSchoolYear.startTime).startOf('day') ||
        current > moment(this.currentSchoolYear.endTime).endOf('day'))
    )
  }

  private filterData(inputValue, path) {
    return path.some(option => option.label.indexOf(inputValue) > -1)
  }

  private getAssignmentInfo(): void {
    TaskController.detail(this.assignmentId)
      .then(res => {
        const {
          description,
          endDate,
          inTotal,
          name,
          online,
          overDeadline,
          resources,
          scoreFlag,
          taskId,
          topScore,
          type,
          course,
          publicFlag,
          startDate,
          canEditType,
        } = res.data
        this.scoreAllow = scoreFlag
        this.editable = canEditType
        this.treeSelectCourses = this.type === 'copyEdit' ? [] : [course.courseId]
        this.courseIds = this.type === 'copyEdit' ? [] : [course.courseId]
        this.description = description
        ;(this.$refs.aSim as any).changeValue(description)
        this.assignmentEditor.setFieldsValue({
          // deadline: moment(endDate),
          deadline: this.dateShow ? moment(endDate) : null,
          inTotal,
          name,
          online: online.toString(),
          overDeadline,
          attachments: resources.map(item => {
            return {
              uid: item.resourceId,
              url: item.resourceUrl,
              name: item.resourceName,
              status: 'done',
            }
          }),
          scoreFlag,
          topScore,
          type: type.taskTypeId,
          publicFlag,
          // startDate: moment(startDate)
          startDate: this.dateShow ? moment(startDate) : null,
        })
        if (this.courseType !== 'subjectClass') {
          this.assignmentEditor.setFieldsValue({ courseIds: this.courseIds })
        }
      })
      .catch(err => console.log(err))
  }

  private getDropDownInfo(): void {
    Promise.all([
      DropDownController.getTaskType(),
      this.courseType === 'subjectClass'
        ? CourseController.getCoursesOptionsCascade()
        : DropDownController.getCourseCcaBySchoolYear(),
    ]).then(res => {
      this.typeList = res[0].data
      this.courses = res[1].data
      if (this.courses.length && this.defaultClassId) {
        if (this.courseType === 'subjectClass') {
          this.courseIds = [this.defaultClassId]
          this.treeSelectCourses = [this.defaultClassId]
        } else {
          this.assignmentEditor.setFieldsValue({ courseIds: [this.defaultClassId] })
        }
      }
    })
  }

  private onAssignTimeChange(type): void {
    this.$nextTick(() => {
      let otherType = type === 'startDate' ? 'deadline' : 'startDate'
      let otherTime = this.assignmentEditor.getFieldValue(otherType)
      if (otherTime) {
        this.assignmentEditor.validateFields(
          [otherType],
          { force: true },
          (err: any, values: any) => {
            if (err) {
              return
            }
          }
        )
      }
    })
  }

  private validateAssignTime(rule, value, cb): void {
    let otherTime = this.assignmentEditor.getFieldValue(
      rule.from === 'startDate' ? 'deadline' : 'startDate'
    )
    if (!otherTime) {
      cb()
      return
    } else if (!value) {
      cb(this.$t('common.select'))
      return
    }
    let legal = rule.from === 'startDate' ? otherTime.isAfter(value) : otherTime.isBefore(value)
    if (legal) {
      cb()
    } else {
      rule.from === 'startDate'
        ? cb(this.$t('assignment.startDateError'))
        : cb(this.$t('assignment.deadlineError'))
    }
  }

  private validateExistType(rule, value, cb) {
    const typeId = this.assignmentEditor.getFieldValue('type')
    const { courseIds } = this
    const type = this.typeList.filter(item => item.taskTypeId === typeId)[0] || {}
    if (typeId && courseIds && courseIds.length && type?.isExam) {
      let condition = {
        typeId,
        taskId: this.$route.query.id && type === 'edit' ? Number(this.$route.query.id) : undefined,
        courseIds: courseIds,
      }
      TaskController.checkExist(condition).then(res => {
        if (!res.data.length) {
          cb()
          return
        }
        let existCourse = res.data.join('、')
        cb(
          this.$t('assignment.courseExistType', {
            courses: existCourse,
            type: this.locale === 'zh' ? type.name : type.enName,
          })
        )
      })
    } else {
      cb()
    }
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    to.meta.routerName = to.query.type !== 'add' ? 'editAssignment' : 'addAssignment'
    if (to.query.type !== 'add') {
      this.getAssignmentInfo()
    }
  }

  private onCourseChange(value) {
    this.courseIds = value
    if (!this.courseIds.length) {
      this.emptyCourse = true
      return
    }
    this.emptyCourse = false
    const type = this.assignmentEditor.getFieldValue('type')
    const typeCode = (this.typeList.filter(item => item.taskTypeId === type)[0] || {}).code
    if (type && (typeCode === '1005' || typeCode === '1006')) {
      this.$nextTick(() => {
        this.assignmentEditor.validateFields(['type'], { force: true }, (err: any, values: any) => {
          if (err) return
        })
      })
    }
  }
}
