


































































































import { Vue, Component, Prop, Model, Emit, Watch } from 'vue-property-decorator'
import { createMergeArray } from '@/utils/utils'
import {
  DropDownController,
  TaskGradeController,
  MonthlyGradeController,
} from '@/services/request.service'
import { filterData } from '@/utils/utils'
import { preventSelection } from '@fullcalendar/core'
import { filter } from 'vue/types/umd'
@Component
export default class RulesEditor extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly detailInfo!: any
  @Prop() private defaultCourse!: any
  @Prop() private type!: any
  @Prop() private schoolYearId!: any
  @Prop({ default: false }) private isHistory!: boolean
  @Prop({ default: [] }) private readonly courses!: any
  private isDisabled: boolean = false
  private filters: any = {
    classId: undefined,
  }
  private data: any = []
  private typeList: any = []
  private loading: boolean = false
  private showTips: boolean = true
  private inited: boolean = false
  private selectedTypes: any = []
  private tabList: any = []
  private gradePeriodId: any = 0
  private periodId: any = ''
  private newData: any = []
  private periodRules: any = []
  private courseId: any = ''
  private periodIds: any = []
  private allRules: any = []
  private defaultData: any = []
  private cancelArr: any = []
  private smpilePercentage: any = 0
  private filterData = filterData

  private get columns(): any {
    return [
      {
        key: 'type',
        title: this.$t('common.type'),
        scopedSlots: { customRender: 'type' },
        width: 200,
      },
      {
        dataIndex: 'colour',
        title: this.$t('common.color'),
        scopedSlots: { customRender: 'color' },
      },
      {
        key: 'status',
        title: this.$t('common.percentage'),
        align: 'left',
        scopedSlots: { customRender: 'percentage' },
      },
    ]
  }
  private get locale(): any {
    return this.$store.state.locale
  }
  // private get mergeCourses(): any {
  //   let courses = []
  //   this.courses.forEach(item => {
  //     createMergeArray(item, courses)
  //   })
  //   return this.type === 'subjectClass' ? courses : this.courses.map(item => ({
  //     ...item,
  //     newKey: item.key
  //   }))
  // }

  private addType(): void {
    this.data.push({
      taskTypeId: undefined,
      colour: undefined,
      percentage: undefined,
      index: this.data.length ? Math.max(...this.data.map(item => item.index)) + 1 : 1,
    })
  }

  private created() {
    this.getTypeList()
    // this.changeTab()
  }
  // @Watch('data', { immediate: true })
  public setPeriodIds() {
    if (this.newData.length === 0 && this.periodId) {
      this.newData.push({
        // [this.periodId]: this.data,
        periodId: this.periodId,
        data: this.data,
      })
      this.periodIds.push(this.periodId)
    }
  }
  private deleteItem(record) {
    const index = this.data.indexOf(record)
    this.data.splice(index, 1)
    this.selectedTypes = this.data.map(item => item.taskTypeId)
  }

  private getData(classId: any): void {
    // this.newData = []
    this.cancelArr = []
    if (!this.visible) return
    let tmpId: any
    if (classId) {
      tmpId = this.type === 'subjectClass' ? classId[1] : classId
    } else {
      tmpId = this.type === 'subjectClass' ? this.filters.classId[1] : this.filters.classId
    }
    if (!tmpId) return
    this.data.forEach(element => {
      this.$set(element, 'percentage', 0)
    })
    this.courseId = tmpId
    TaskGradeController.getGradePeriodForRule(tmpId).then(res => {
      this.tabList = res.data
      this.tabList.map(it => {
        if (it.status !== 'lock') {
          this.cancelArr.push(it.gradePeriodId)
        }
      })
      if (this.gradePeriodId === 0) {
        this.periodId = this.tabList[0].gradePeriodId
        this.setPeriodIds()
      } else {
        this.periodId = this.tabList[this.gradePeriodId].gradePeriodId
      }
    })
    this.setData()
    // TaskGradeController.getRoleEntryList(tmpId, this.schoolYearId).then((res) => {
    //   res.data.forEach((item: any) => {
    //     if (item.gradePeriodId === this.gradePeriodId) {
    //       let task = this.data.find((task) => task.taskTypeId === item.taskTypeId)
    //       if (task) task.percentage = item.percentage
    //     }
    //   })
    //   this.data.sort((a, b) => b.percentage - a.percentage)
    // })
  }

  private getTypeList(): void {
    DropDownController.getTaskType().then(res => {
      this.typeList = res.data
      this.data = this.typeList.map(item => ({
        ...item,
        percentage: 0,
      }))
    })
  }
  private getDefaultData() {
    this.defaultData = []
    if (this.courseId === '') {
      this.courseId = this.type === 'subjectClass' ? this.filters.classId[1] : this.filters.classId
    }
    TaskGradeController.getRoleEntryList(this.courseId, this.schoolYearId).then(res => {
      res.data.map(item => {
        const rules = item.rules.map(iten => {
          return {
            taskTypeId: iten.taskTypeId,
            percentage: iten.percentage || 0,
          }
        })
        console.log(this.cancelArr, '12')
        // if (item.status !== 'lock')
        if (this.cancelArr.includes(item.gradePeriodId)) {
          this.defaultData.push({
            gradePeriodId: item.gradePeriodId,
            rules: rules,
          })
        }
      })
    })
  }
  @Watch('periodId', { immediate: true })
  private setData() {
    if (this.periodId) {
      if (this.courseId === '') {
        this.courseId =
          this.type === 'subjectClass' ? this.filters.classId[1] : this.filters.classId
      }
      TaskGradeController.getRoleEntryList(this.courseId, this.schoolYearId).then(res => {
        res.data.forEach((item: any) => {
          if (item.gradePeriodId === this.periodId) {
            this.data.map(iten => {
              item.rules.map(it => {
                if (iten.taskTypeId === it.taskTypeId) {
                  iten.percentage = it.percentage
                }
              })
            })
          }
        })
        this.data.sort((a, b) => b.percentage - a.percentage)
      })
    }
  }
  private saveRule(): void {
    this.periodRules = []
    let percentageFlag = true
    let undefinedFlag = false
    if (this.newData.length === 0) {
      this.closeModal()
    } else if (this.newData.length === 1) {
      // this.newData = this.data
      this.newData[0] = {
        data: this.data,
        periodId: this.periodId,
      }
      let task = this.newData[0].data.find(task => task.percentage !== 0)
      if (!task) {
        this.closeModal()
      } else {
        this.newData.map((iten, index) => {
          // let totalPercentage = 0
          const rules = iten.data.map(item => {
            totalPercentage += item.percentage || 0

            if (!item.taskTypeId) {
              undefinedFlag = true
            }
            return {
              taskTypeId: item.taskTypeId,
              percentage: item.percentage || 0,
            }
          })
          this.periodRules.push({
            gradePeriodId: iten.periodId,
            rules: rules,
          })
          let totalPercentage = 0
          iten.data.map(item => {
            totalPercentage += item.percentage
          })

          if (totalPercentage !== 100) {
            if (totalPercentage === 0) {
              this.newData.splice(index, 1)
            } else {
              percentageFlag = false
            }
          }
        })
        if (undefinedFlag) {
          this.$message.error(this.$tc('tips.selectAssignmentType'))
          return
        }
        if (!percentageFlag) {
          this.$message.error(this.$tc('grading.scoreSheet.warningTips'))
          return
        }
        if (percentageFlag) {
          let condition: any = {
            courseId: this.type === 'subjectClass' ? this.filters.classId[1] : this.filters.classId,
            periodRules: this.periodRules,
          }
          if (this.periodRules.length === 0) {
            this.closeModal()
            return
          }
          if (!condition?.courseId) return
          TaskGradeController.updateGradeRules(condition)
            .then(res => {
              this.$message.success(this.$tc('tips.updateRulesSuccess'))
            })
            .catch(err => console.log(err))
            .finally(() => {
              this.$emit('ruleChange')
              this.newData = []
              // this.data = []
              this.periodIds = []
              this.closeModal()
              this.getData(this.defaultCourse)
            })
        }
      }
    } else {
      if (undefinedFlag) {
        this.$message.error(this.$tc('tips.selectAssignmentType'))
        return
      }
      if (!percentageFlag) {
        this.$message.error(this.$tc('grading.scoreSheet.warningTips'))
        return
      }
      const centerData = this.data
      let rules: any = []
      this.smpilePercentage = 0
      centerData.map(item => {
        this.smpilePercentage += item.percentage
        rules.push({
          percentage: item.percentage,
          taskTypeId: item.taskTypeId,
        })
      })
      this.tabList.map(item => {
        if (item.gradePeriodId === this.periodId) {
          if (item.status !== 'lock') {
            if (this.smpilePercentage === 100) {
              let condition: any = {
                courseId:
                  this.type === 'subjectClass' ? this.filters.classId[1] : this.filters.classId,
                periodRules: [
                  {
                    gradePeriodId: this.periodId,
                    rules: rules,
                  },
                ],
              }
              TaskGradeController.updateGradeRules(condition)
                .then(res => {
                  this.$message.success(this.$tc('tips.updateRulesSuccess'))
                })
                .catch(err => console.log(err))
                .finally(() => {
                  this.$emit('ruleChange')
                  this.closeModal()
                })
            } else {
              this.$message.error(this.$tc('grading.scoreSheet.warningTips'))
            }
          }
        }
      })
    }
  }

  private onTypeChange(item, params, options) {
    this.selectedTypes = this.data.map(item => item.taskTypeId)
    item.colour = options.data.props.colour
  }
  private changeSelect(value: any) {
    this.gradePeriodId = 0
    this.getData(value)
    this.periodIds = []
    this.getDefaultData()
  }
  private changeTab(value) {
    const centerData = this.data
    let rules: any = []
    this.smpilePercentage = 0
    centerData.map(item => {
      this.smpilePercentage += item.percentage
      rules.push({
        percentage: item.percentage,
        taskTypeId: item.taskTypeId,
      })
    })
    this.tabList.map(item => {
      if (item.gradePeriodId === this.periodId) {
        if (item.status !== 'lock') {
          if (this.smpilePercentage === 100) {
            let condition: any = {
              courseId:
                this.type === 'subjectClass' ? this.filters.classId[1] : this.filters.classId,
              periodRules: [
                {
                  gradePeriodId: this.periodId,
                  rules: rules,
                },
              ],
            }
            TaskGradeController.updateGradeRules(condition)
              .then(res => {})
              .catch(err => console.log(err))
              .finally(() => {})
          }
        }
      }
    })

    this.periodId = this.tabList[value.target.value].gradePeriodId
    this.data = this.typeList.map(item => ({
      ...item,
      percentage: 0,
    }))
    if (!this.periodIds.includes(this.periodId)) {
      this.newData.push({
        periodId: this.periodId,
        data: this.data,
      })
      this.periodIds.push(this.periodId)
    } else {
      this.newData.map((item: any) => {
        if (this.periodId === item.periodId) {
          this.data = item.data
          console.log(this.periodIds)
        }
      })
    }
    // }
  }
  private confirmApply() {
    this.applyAll()
  }
  private cancelApply() {}
  private applyAll() {
    this.allRules = []
    this.newData.map(item => {
      if (this.periodId === item.periodId) {
        let newData: any = []
        this.tabList.map(iten => {
          if (iten.status !== 'lock') {
            newData.push({
              periodId: iten.gradePeriodId,
              data: item.data,
            })
          }
        })
        this.newData = newData
      }
    })
    let simplePercentage = 0
    this.newData[0].data.map(item => {
      simplePercentage += item.percentage
    })

    if (simplePercentage === 100) {
      this.newData.map(item => {
        const rules = item.data.map(iten => {
          return {
            taskTypeId: iten.taskTypeId,
            percentage: iten.percentage || 0,
          }
        })
        this.allRules.push({
          gradePeriodId: item.periodId,
          rules: rules,
        })
      })
      let condition: any = {
        courseId: this.type === 'subjectClass' ? this.filters.classId[1] : this.filters.classId,
        periodRules: this.allRules,
      }
      if (!condition?.courseId) return
      TaskGradeController.updateGradeRules(condition)
        .then(res => {})
        .catch(err => console.log(err))
        .finally(() => {
          this.$emit('ruleChange')
          this.newData = []
          this.periodIds = []
          let tmpId = this.type === 'subjectClass' ? this.filters.classId[1] : this.filters.classId
          this.getData(tmpId)
        })
    } else {
      this.$message.error(this.$tc('grading.scoreSheet.warningTips'))
    }
  }
  private clearData(): void {
    this.filters = {
      classId: undefined,
    }
    this.data = this.typeList.map(item => ({
      ...item,
      percentage: 0,
    }))
    this.showTips = true
    this.selectedTypes = []
  }

  @Emit('change')
  private closeModal(): boolean {
    return false
  }
  private setDefaultData() {
    if (this.defaultData.length !== 0) {
      let condition: any = {
        courseId: this.type === 'subjectClass' ? this.filters.classId[1] : this.filters.classId,
        periodRules: this.defaultData,
      }
      TaskGradeController.updateGradeRules(condition)
        .then(res => {})
        .catch(err => console.log(err))
        .finally(() => {
          this.closeModal()
        })
    } else {
      this.closeModal()
    }
  }
  @Emit('cancel')
  private cancel(): boolean {
    this.closeModal()
    return false
  }

  @Watch('visible')
  private onVisibleChange(val): void {
    if (val) {
      this.filters.classId = this.defaultCourse
      this.gradePeriodId = 0
      this.getData(this.filters.classId)
      this.setData()
      this.getDefaultData()

      if (!this.inited) return
    } else {
      this.periodIds = []
      this.clearData()
    }
  }

  // @Watch('mergeCourses')
  // private onCoursesChange(val): void{
  //   const selectCourse = ((val || []).filter(item => item.key === Number(this.defaultCourse))[0] || {}).newKey
  //   this.filters.classId = selectCourse
  //   this.inited =  true
  // }

  @Watch('defaultCourse')
  private onDefaultChange(val): void {
    if (!val) return
    this.getData(val)
    this.filters.classId = val
    this.inited = true
  }
}
