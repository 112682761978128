


































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import {
  CourseController,
  DropDownController,
  TaskGradeController,
} from '@/services/request.service'
import { clearEmptyArray, getSchoolInfo, createMergeArray, isLegal, Debounce } from '@/utils/utils'
import moment from 'moment'
import rulesEditor from './RulesEditor.vue'
import filterGroup from '@/components/filterGroup'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { exportXlsx, writeFile } from '@/utils/xlsx'
import cloneDeep from 'lodash/cloneDeep'
import round from 'lodash/round'
import SvgIcon from '@/components/SvgIcon.vue'
import { SimditorVue } from '@/components/simditor/Simditor'

const operations = {
  subjectClass: function(condition) {
    return TaskGradeController.getStudentTaskGrades(condition.schoolYearId, condition.courseId)
  },
  CCAClass: function(condition) {
    return TaskGradeController.getCCAStudentTaskGrades(condition.schoolYearId, condition.courseId)
  },
}

@Component({
  components: {
    filterGroup,
    rulesEditor,
    FlexTooltip,
    SvgIcon,
    SimditorVue,
  },
})
export default class ScoreSheet extends Vue {
  @Prop() private readonly type!: string
  @Prop() private readonly schoolYear!: any
  @Prop() private readonly courseId!: Array<any> | number
  @Prop() private readonly courses!: Array<any>

  private drawerInfo: any = {}
  private drawerVisible: boolean = false
  private data: any = []
  private dataDup: any = []
  private docViewVis: any = false
  private docUrl: any = ''
  private docType: any = ''
  private dynamicHeader: any = []
  private currentFocus: any = {}
  private editForm: any
  private editStatus: boolean = false
  private filters: any = {
    schoolYearId: undefined,
    courseId: [],
  }
  private editFlag: boolean = true
  private loading: boolean = false
  private nextVisible: boolean = true
  private lastVisible: boolean = true
  private rulesVisible: boolean = false
  private editStudent: any
  private editTaskId: any
  private schoolYearList: any = []
  private updateLoading: boolean = false
  private exportLoading: boolean = false
  private drawerLoading: boolean = false
  private scoreRules: any = {}
  private windowH: any = 696
  private round = round
  private hiddenVis = false
  private disStudent = []
  private mode: any = 'view'
  private needBlur: boolean = true
  private fsRulesVisible: any = false
  private changedStudent: any = []
  private studentMenuKey: any = []
  private schoolId: any = (getSchoolInfo() || {}).schoolId
  private fsRules: any = {
    finalScoreRuleId: 0,
    courseId: 0,
    averageFactor: 0,
    midFactor: 0,
    endFactor: 0,
    error: false,
  }
  private fsRulesDup: any = {
    finalScoreRuleId: 0,
    courseId: 0,
    averageFactor: 0,
    midFactor: 0,
    endFactor: 0,
    error: false,
  }
  private fsRulesError: boolean = false

  private get isHistoryYear(): boolean {
    let year = this.schoolYearList.find(item => item.key === this.filters.schoolYearId)
    return year && year.extraValue === '1102'
  }
  /**
   * 手动通过flag
   */
  private get manualPass(): any {
    return this.realData[this.drawerInfo.index]?.manual || false
  }

  private get outClassTask(): any {
    return !this.drawerInfo?.canEdit || false
  }

  private get excelColumns(): Array<any> {
    return [
      {
        dataIndex: 'lastName',
        title: this.$t('common.surname'),
        width: 10,
      },
      {
        dataIndex: 'enName',
        title: this.$t('common.enName'),
        width: 10,
      },
      {
        dataIndex: 'firstName',
        title: this.$t('common.givenName'),
        width: 10,
      },
      {
        dataIndex: 'name',
        title: this.$t('common.cnName'),
        width: 10,
      },
      ...this.columns.slice(1).map(item => ({
        ...item,
        dataIndex: item.key === 'finalScore' ? 'finalScore' : item.dataIndex,
        width: 20,
      })),
    ]
  }
  private get columns(): Array<any> {
    let dynamicColumns = []
    dynamicColumns = this.dynamicHeader.map(item => {
      const monthCode = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sept',
        'Oct',
        'Nov',
        'Dec',
      ]
      const timeString = `${monthCode[moment(item.createTime).month()]}.${moment(
        item.createTime
      ).date()}`
      return {
        dataIndex: item.taskId,
        key: item.taskId,
        title:
          item.taskType === 'semester'
            ? `${item.taskName}\nPercentage`
            : `${item.abbr || ''}:${item.taskName} \n (${timeString}) \n max: ${item.max}`,
        filterDropdown: !item.studentVisible || !item.inTotal || item.taskType === 'semester', // 自定义的列筛选功能，我们占位为信息提示Icon的位置
        filterIcon:
          item.taskType !== 'semester'
            ? () => {
                return this.$createElement(
                  'div',
                  {},
                  [
                    !item.studentVisible
                      ? this.$createElement(
                          'a-tooltip',
                          {
                            props: {
                              title: this.$t('grading.scoreSheet.hideTips'),
                              getPopupContainer: () => this.$document.body,
                            },
                          },
                          [
                            this.$createElement('img', {
                              attrs: {
                                src: require('@/assets/images/Grading/ScoreSheet/hide.png'),
                              },
                              style: 'width: 16px;height:16px',
                            }),
                          ]
                        )
                      : undefined,
                    !item.inTotal
                      ? this.$createElement(
                          'a-tooltip',
                          {
                            props: {
                              title: this.$t('grading.scoreSheet.notIncludeTips'),
                              getPopupContainer: () => this.$document.body,
                            },
                          },
                          [
                            this.$createElement('img', {
                              attrs: {
                                src: require('@/assets/images/Grading/ScoreSheet/notInTotal.png'),
                              },
                              style: 'width: 16px;height:16px',
                            }),
                          ]
                        )
                      : undefined,
                  ].filter(item => item)
                )
              }
            : undefined,
        scopedSlots: {
          customRender:
            this.mode === 'view'
              ? item.taskType === 'semester'
                ? 'sDynamicColumns'
                : 'dynamicColumns'
              : 'scoreBlock',
          filterIcon: 'semesterFilter',
        },
        customHeaderCell: () => ({
          style: {
            background: item.color,
            whiteSpace: 'pre-line',
            borderTop: item.taskType === 'semester' ? '1px solid #e8e8e8' : '1px solid #fff',
            borderLeft: item.taskType === 'semester' ? '1px solid #e8e8e8' : '1px solid #fff',
            cursor: item.taskType !== 'semester' && this.mode === 'view' ? 'pointer' : '',
          },
          attrs: {
            title: item.taskType,
          },
          on: item.taskType !== 'semester' &&
            this.mode === 'view' && {
              click: () => {
                this.toTaskDetail(item)
              },
            },
        }),
        customCell: (record, index) => {
          return {
            style: {
              background:
                item.taskType !== 'semester'
                  ? 'rgba(240,242,245,0.65)'
                  : !record[item.taskId]?.manualScore || this.mode === 'score'
                  ? ''
                  : '#f7ac21',
              borderRight: '1px solid #fff',
              borderBottom:
                item.taskType !== 'semester' && this.data.length - 1 !== index
                  ? '1px solid #fff'
                  : '1px solid #e8e8e8',
              borderLeft: item.taskType === 'semester' ? '1px solid #e8e8e8' : '1px solid #fff',
            },
            class: 'dynamic-cell',
          }
        },
        bgColor: item.color.replace('#', ''),
        isTask: item.taskType !== 'semester',
        isLocked: item.taskType === 'semester' && !item.editFlag,
      }
    })
    return [
      {
        dataIndex: 'studentName',
        key: 'name',
        title: this.$t('common.personName'),
        ellipsis: true,
        fixed: 'left',
        width: 220,
        customCell: (record, index) => {
          return {
            attrs: {
              colspan: index <= 1 ? 2 : 1,
            },
          }
        },
        scopedSlots: { customRender: 'name' },
      },
      {
        dataIndex: 'studentNum',
        key: 'studentId',
        fixed: 'left',
        ellipsis: true,
        width: 120,
        customCell: (record, index) => {
          return {
            attrs: {
              colspan: index <= 1 ? 0 : 1,
            },
            style: {
              display: index <= 1 ? 'none' : '',
            },
          }
        },
        title: this.$t('common.studentId'),
      },
      {
        key: 'finalScore',
        title: this.$t('grading.scoreSheet.finalScore'),
        fixed: 'left',
        ellipsis: true,
        width: 120,
        scopedSlots: { customRender: this.mode === 'view' ? 'finalScore' : 'scoreBlock' },
        customCell: record => ({
          class: 'dynamic-cell',
          style: { background: !record.manual && record.finalGrade ? '#f7ac21' : '' },
        }),
      },
      ...dynamicColumns,
    ]
  }
  private callback() {}
  private get locale(): any {
    return this.$store.state.locale
  }
  private get markList(): any {
    return [
      {
        key: 'missing',
        label: this.$t('grading.scoreSheet.mark.missing'),
      },
      {
        key: 'incomplete',
        label: this.$t('grading.scoreSheet.mark.incomplete'),
      },
      {
        key: 'late',
        label: this.$t('grading.scoreSheet.mark.late'),
      },
    ]
  }
  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }
  private get schoolYearId(): any {
    return this.$store.state.currentSchoolYear.schoolYearId
  }
  private get mergeCourses(): any {
    let courses = []
    this.courses.forEach(item => {
      createMergeArray(item, courses)
    })
    return this.type === 'subjectClass'
      ? courses
      : this.courses.map(item => ({
          ...item,
          newKey: item.key,
        }))
  }
  private get realData(): any {
    const data = this.mode === 'view' ? this.data : cloneDeep(this.data)
    return data.filter(item => !item.outFlag || this.hiddenVis)
  }

  private beforeFSClose(): void {
    let modify = false
    Object.keys(this.fsRules).forEach(key => {
      if (this.fsRules[key] !== this.fsRulesDup[key]) {
        modify = true
      }
    })
    if (modify) {
      this.$confirm({
        title: this.$t('common.unsaveConfirm') as string,
        onOk: () => {
          this.fsRulesVisible = false
          this.fsRulesError = false
        },
        onCancel: () => {},
      })
    } else {
      this.fsRulesVisible = false
      this.fsRulesError = false
    }
  }

  private calcDate(date): any {
    const formatDate = moment(date).format('YYYY-MM-DD')
    return this.$t(`myClass.1017`, { date: formatDate })
  }

  private cancelScore(): any {
    let modify = this.checkIfScoreModify()
    if (modify) {
      this.$confirm({
        title: this.$t('common.unsaveConfirm') as string,
        onOk: () => {
          this.mode = 'view'
          this.currentFocus = {}
        },
        onCancel: () => {},
      })
    } else {
      this.mode = 'view'
      this.currentFocus = {}
    }
  }

  /**
   * 录入成绩模式下,校验学分是否修改
   */
  private checkIfScoreModify(): boolean {
    let flag = false
    this.changedStudent.forEach(student => {
      if (Object.keys(student.taskGradesMap).length) {
        flag = true
      }
    })
    return flag
  }

  private changeMenu({ item, key, keyPath }): void {
    this.studentMenuKey = [key]
    let idx = this.realData.findIndex(item => item.studentId === key)
    console.log(idx, key, this.realData)
    this.setDetail(
      this.drawerInfo.type === 'final'
        ? this.realData[idx]?.finalScore
        : this.realData[idx]?.[this.editTaskId]?.score,
      this.realData[idx],
      this.drawerInfo.type,
      this.editTaskId,
      idx,
      this.operationAuths.includes('2086')
    )
  }

  /**
   * 关闭打分窗口时,判断需不需要保存
   */
  private async closeDrawer(needSave): Promise<any> {
    if (needSave && this.operationAuths.includes('2086')) {
      const result = await this.saveScore()
      if (result !== 'success') return
    }
    this.drawerInfo = {}
    this.drawerVisible = false
    this.editStudent = undefined
  }

  private createFileName(resourceName): any {
    const index = resourceName.lastIndexOf('.')
    const suffix = resourceName.slice(index)
    return `${this.drawerInfo.title}_${this.drawerInfo.name}${suffix}`
  }

  /**
   * 导出成绩册逻辑
   */
  private async exportStudents(): Promise<void> {
    this.exportLoading = true
    const { data } = this
    let students = cloneDeep(data)
      .filter(item => item.canEdit !== false)
      .map(item => {
        const score = item.manual ? 60 : item.finalGrade || item.finalScore
        const level = item.manual ? 'P' : this.score2Level(score)
        return {
          ...item,
          finalScore: {
            score: `${score}/${level}`,
            fill: {
              type: 'pattern',
              pattern: 'darkTrellis',
              fgColor: { argb: `${!item.manual && item.finalGrade ? 'FFF7AC21' : 'FFFFFFFF'}` },
              bgColor: { argb: `${!item.manual && item.finalGrade ? 'FFF7AC21' : 'FFFFFFFF'}` },
            },
          },
        }
      })
    const schoolYear = this.schoolYear.value
    const id = this.type === 'subjectClass' ? this.courseId?.[1] : this.courseId
    const course = this.mergeCourses.find(item => item.key === id)?.value

    students.forEach(student => {
      this.dynamicHeader.forEach(item => {
        if (!student?.[item.taskId]) return
        const tag = student[item.taskId]?.tag
        const color =
          tag === 'late'
            ? 'FF8D19'
            : tag === 'missing'
            ? 'D43030'
            : tag === 'incomplete'
            ? '2A82E4'
            : undefined
        this.$set(
          student[item.taskId],
          'fill',
          color
            ? {
                type: 'pattern',
                pattern: 'darkTrellis',
                fgColor: { argb: `FF${color}` },
                bgColor: { argb: `FF${color}` },
              }
            : undefined
        )
      })
    })

    const file = exportXlsx(this.excelColumns, students)
    const ws = file.getWorksheet(1)

    file
      .getWorksheet(1)
      .getRow(1)
      .eachCell((cell, index) => {
        if (!this.excelColumns[index - 1].bgColor) return
        cell.fill = {
          type: 'pattern',
          pattern: 'darkTrellis',
          fgColor: { argb: `FF${this.excelColumns[index - 1].bgColor}` },
          bgColor: { argb: `FF${this.excelColumns[index - 1].bgColor}` },
        } as any
      })
    ws.eachRow(row => {
      row.height = 30
      row.eachCell((cell: any) => {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } },
        }
        cell.style.alignment = { vertical: 'middle', horizontal: 'center' }
        if (typeof cell.value === 'object') {
          let value = cloneDeep(cell.value)
          if (cell.value.type === 'semester') {
            let score: any = ''
            let level = ''
            if (!value.manual) {
              score = value.score
              level = this.score2Level(score)
            } else {
              score = value.manualScore
              level = this.score2Level(value.manualScore)
            }
            cell.value = `${score}/${level}`
          } else {
            const score = value.score
            cell.value = score
          }
          cell.fill =
            (value.manualScore || value.manualScore === 0) && value.manualScore !== -1
              ? {
                  type: 'pattern',
                  pattern: 'darkTrellis',
                  fgColor: { argb: 'FFF7AC21' },
                  bgColor: { argb: 'FFF7AC21' },
                }
              : value.fill
        }
      })
    })
    ws.mergeCells(2, 1, 2, 5)
    ws.mergeCells(3, 1, 3, 5)

    await writeFile(file, `${schoolYear}_${course}_Gradebook.xlsx`)
    this.exportLoading = false
  }

  private filterData(inputValue, path) {
    return path.some(option => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
  }

  private getData(): void {
    this.data = []
    this.dynamicHeader = []
    this.mode = 'view'
    this.currentFocus = {}
    this.changedStudent = []
    this.dataDup = []
    const courseId = this.type === 'subjectClass' ? this.courseId[1] : this.courseId
    if (!courseId) return
    this.loading = true
    const type = this.type
    const condition = {
      schoolYearId: this.schoolYear.key,
      courseId: this.type === 'subjectClass' ? this.courseId[1] : this.courseId,
    }
    operations[this.type](condition)
      .then(res => {
        this.editFlag = res.data.editFlag
        if (this.type !== type) return
        this.dynamicHeader = res.data.tasks
          .map(item => {
            const taskGrade = item.tasks.map(task => ({
              ...task,
            }))
            return [
              {
                ...item,
                color: '#ffffff',
                inTotal: true,
                studentVisible: item.studentVisible,
                taskId: 's' + item.gradePeriodId,
                taskName: item.gradePeriod,
                taskType: 'semester',
                gradePeriodId: item.gradePeriodId,
              },
              ...taskGrade,
            ]
          })
          .flat(1)
          .filter(
            item =>
              item.taskType !== 'semester' ||
              (item.taskType === 'semester' && item.gradePeriodId !== null)
          )
        this.data = res.data.items.map(item => {
          let semester = {}
          let taskGradeMap = {}
          this.dynamicHeader.forEach(header => {
            if (header.taskType !== 'semester') {
              if (item.taskGradesMap?.[header.taskId]) {
                let score = item.taskGradesMap?.[header.taskId]?.score
                let data = {
                  ...item.taskGradesMap?.[header.taskId],
                  score: score ? round(score, 1) : score,
                  type: header.taskType,
                  canEdit: true, //退班任务不可编辑flag
                }
                this.$set(taskGradeMap, header.taskId, data)
              } else {
                this.$set(taskGradeMap, header.taskId, {
                  comment: '',
                  commit: false,
                  // score: '',
                  score: undefined,
                  tag: 'none',
                  taskStudentId: 0,
                  canEdit: false, //退班任务不可编辑flag
                })
              }
            } else {
              let score = item.gradePeriodScores?.[header.gradePeriodId]?.score
              let manualScore = item.gradePeriodScores?.[header.gradePeriodId]?.manualScore
              let data = {
                ...item.gradePeriodScores?.[header.gradePeriodId],
                score: score === -1 || (!score && score !== 0) ? '--' : round(score * 100, 1),
                manualScore: manualScore ? round(manualScore, 1) : manualScore,
                manual: manualScore || manualScore === 0,
                type: header.taskType,
              }
              this.$set(semester, header.taskId, data)
            }
          })
          return {
            ...item,
            ...taskGradeMap,
            ...semester,
            finalScore:
              item.finalScore === -1 || (!item.finalScore && item.finalScore !== 0)
                ? '--'
                : round(item.finalScore * 100, 1),
            finalGrade: item.finalGrade ? round(item.finalGrade, 1) : item.finalGrade,
            // manual: item.finalGrade || item.finalGrade === 0,
            isStudent: true,
          }
        })
        this.disStudent = this.data.filter(item => item.outFlag)
        const normalStudent = this.data.filter(item => !item.outFlag)
        const notStudent = this.getNotStudentData(normalStudent)
        this.data = [].concat(notStudent, normalStudent, this.disStudent)
        this.scoreRules = res.data.levelMap || {}
      })
      .finally(() => (this.loading = false))
  }

  /**
   * 学分换算成绩
   */
  private score2Level(score): any {
    if (score < 0 || typeof score !== 'number' || isNaN(score)) return '--'
    const tScore = round(score, 1)
    if (!this.scoreRules[0]) {
      this.$set(this.scoreRules, '0', '--')
    }
    return (
      this.scoreRules[
        Object.keys(this.scoreRules).reduce((pre, cur) =>
          tScore >= Number(pre) && tScore < Number(cur) ? pre : cur
        )
      ] || '--'
    )
  }

  private getSemesterName(key): any {
    const semester = this.dynamicHeader.find(item => item.taskId === key)
    return `${semester.gradePeriod}：${Vue.filter('doubleMoment')(
      [semester.startTime, semester.endTime],
      'YYYY.MM.DD HH:mm'
    )}`
  }

  /**
   * 单个学生修改任务标记处理, 复选取消选择
   */
  private onMarkChange(key): void {
    this.drawerInfo.mark = key === this.drawerInfo.mark ? '' : key
  }

  /**
   * 单个学生打分保存,在上一个下一个以及完成时自动打分
   */
  private saveScore(): any {
    return new Promise(resolve => {
      if (this.drawerInfo.canEdit || !this.manualPass) {
        ;(this.$refs['drawerForm'] as any).validate(valid => {
          if (valid) {
            const type = this.drawerInfo.type
            this.updateLoading = true
            if (type === 'semester') {
              let condition: any = {
                studentId: this.drawerInfo.id,
                // comment: this.drawerInfo.comment,
                finalGrade:
                  this.drawerInfo.customScore || this.drawerInfo.customScore === 0
                    ? this.drawerInfo.customScore
                    : undefined,
                courseId: this.type === 'subjectClass' ? this.courseId[1] : this.courseId,
                gradePeriodId: this.drawerInfo.gradePeriodId,
              }
              // 成绩册重构
              TaskGradeController.updateFinalComment(condition)
                .then(res => {
                  const fScore = res.data.value
                  let student = this.data.filter(item => item.studentId === condition.studentId)[0]
                  student[`s${this.drawerInfo.gradePeriodId}`].manualScore = condition.finalGrade
                  // student[`s${this.drawerInfo.gradePeriodId}`].manual =
                  //   !!condition.finalGrade || condition.finalGrade === 0
                  student.finalScore =
                    fScore === -1 || (!fScore && fScore !== 0) ? '--' : round(fScore * 100, 1)
                  const notStudent = this.getNotStudentData(this.data.filter(item => item.canEdit))
                  this.data = notStudent.concat(this.data.slice(2))
                  this.$message.success(this.$tc('tips.gradeSaved'))
                })
                .catch(err => console.log(err))
                .finally(() => {
                  this.updateLoading = false
                  resolve('success')
                })
            } else if (type === 'final') {
              let condition = {
                studentId: this.drawerInfo.id,
                finalGrade:
                  this.drawerInfo.customScore || this.drawerInfo.customScore === 0
                    ? this.drawerInfo.customScore
                    : undefined,
                courseId: this.type === 'subjectClass' ? this.courseId[1] : this.courseId,
              }
              TaskGradeController.updateFinalScore(condition)
                .then(res => {
                  let student = this.data.find(item => item.studentId === condition.studentId)
                  student.finalGrade = condition.finalGrade
                  // student.manual = !!condition.finalGrade || condition.finalGrade === 0
                  const notStudent = this.getNotStudentData(this.data.filter(item => item.canEdit))
                  this.data = notStudent.concat(this.data.slice(2))
                  this.$message.success(this.$tc('tips.gradeSaved'))
                })
                .finally(() => {
                  this.updateLoading = false
                  resolve('success')
                })
            } else {
              let condition = {
                studentId: this.drawerInfo.id,
                courseId: this.type === 'subjectClass' ? this.courseId[1] : this.courseId,
                schoolYearId: this.schoolYear.key,
                taskId: this.editTaskId,
                score: this.drawerInfo.score === 0 ? 0 : this.drawerInfo.score || undefined,
                tag: this.drawerInfo.mark || 'none',
                comment: this.drawerInfo.comment,
              }
              TaskGradeController.updateScore(condition)
                .then(res => {
                  let student = this.data.filter(item => item.studentId === condition.studentId)[0]
                  student[condition.taskId].tag = condition.tag
                  student[condition.taskId].score = condition.score
                  student[condition.taskId].comment = condition.comment
                  const fScore = res.data.finalScore
                  student.finalScore =
                    fScore === -1 || (!fScore && fScore !== 0) ? '--' : round(fScore * 100, 1)
                  // student.finalGrade = -1
                  Object.keys(res.data.gradePeriodScores || {}).map(key => {
                    const sScore = res.data.gradePeriodScores?.[key]?.score
                    let data = {
                      ...(student[`s${key}`] || {}),
                      score:
                        sScore === -1 || (!sScore && sScore !== 0) ? '--' : round(sScore * 100, 1),
                      type: 'semester',
                    }
                    // console.log(student, key, data)
                    this.$set(student, 's' + key, data)
                  })
                  this.$message.success(this.$tc('tips.gradeSaved'))
                  const notStudent = this.getNotStudentData(
                    this.data.filter(item => !item.isStudent)
                  )
                  this.data = notStudent.concat(this.data.slice(2))
                })
                .catch(err => console.log(err))
                .finally(() => {
                  this.updateLoading = false
                  resolve('success')
                })
            }
          } else {
            resolve('validateFailed')
            return false
          }
        })
      } else {
        resolve('success')
      }
    })
  }

  private saveFSRule(): void {
    const { averageFactor, midFactor, endFactor } = this.fsRules
    let total = averageFactor + midFactor + endFactor
    this.fsRules.error = !isLegal(averageFactor) || !isLegal(midFactor) || !isLegal(endFactor)
    if (this.fsRules.error) return
    const courseId = this.type === 'subjectClass' ? this.courseId[1] : this.courseId
    TaskGradeController.saveFinalScore({
      ...this.fsRules,
      courseId,
    }).then(res => {
      this.fsRulesVisible = false
      this.getData()
      this.$message.success(this.$tc('tips.updateSuccess'))
    })
  }

  /**
   * 成绩录入
   */
  private scoreIn(): any {
    this.hiddenVis = false
    // 如果是视图模式,将当前模式设置为成绩录入模式
    // 生成数据备份, 专门用于录入修改, 不影响原始数据
    if (this.mode === 'view') {
      this.mode = 'score'
      // this.needBlur = true
      const data = cloneDeep(this.data)
      this.dataDup = data
        // .filter((item) => !item.isStudent || item.canEdit)
        .map(item => {
          this.columns
            .slice(2)
            .map(item => item.key)
            .forEach(key => {
              if (key === 'finalScore') {
                item.score = !item.manual ? item.finalScore : item.finalGrade
              } else if (key[0] === 's') {
                item[key].score = !item[key].manual ? item[key].score : item[key].manualScore
              }
            })
          return item
        })
      return
    }

    // 如果是完成录入,直接提交
    TaskGradeController.batchUpdateScore(this.changedStudent)
      .then(res => {
        this.getData()
        this.$message.success(this.$tc('tips.gradeSaved'))
        this.mode = 'view'
      })
      .finally(() => (this.changedStudent = []))
  }

  private mounted(): any {
    setTimeout(() => {
      this.windowH = (this.$refs.scoreSheet as Element)?.getBoundingClientRect().height || 696
    }, 0)
    window.addEventListener('resize', this.onWindowResize)
    document.addEventListener('keydown', this.keydown, true)
    this.$once('hook:beforeDestory', () => {
      document.removeEventListener('keydown', this.keydown, true)
      window.removeEventListener('resize', this.onWindowResize)
    })
  }

  @Debounce(500)
  private onWindowResize(): void {
    this.windowH = (this.$refs.scoreSheet as Element)?.getBoundingClientRect().height || 696
  }

  /**
   * 设置当前打分学生的详情
   */
  private async setDetail(score, student, type, taskId, index, needSave): Promise<any> {
    this.docViewVis = false
    if (needSave) {
      const result = await this.saveScore()
      if (result !== 'success') return
    }
    this.studentMenuKey = [student.studentId]
    let max = (this.dynamicHeader.filter(item => item.taskId === taskId)[0] || {}).max
    this.nextVisible = !this.validateIsLast(index)
    this.lastVisible = !this.validateIsFirst(index)
    this.editTaskId = taskId
    this.editStudent = student
    const title =
      type === 'final'
        ? this.$t('grading.scoreSheet.finalScore')
        : this.dynamicHeader.filter(item => item.taskId === taskId)[0].taskName

    this.drawerVisible = true
    if (type === 'normal') {
      const task = student[taskId]
      if (task.canEdit) {
        this.drawerLoading = true
        TaskGradeController.getStudentGradeDetail(task.taskStudentId)
          .then(res => {
            // console.log(student, task.taskStudentId)
            this.drawerInfo = {
              type,
              score: this.dynamicHeader.length ? res.data.score : '-',
              level: student.gradeLevel || '-',
              comment: res.data.comment,
              content: res.data.content || 'Empty...',
              attachments: res.data.resources,
              mark: type !== 'final' ? res.data.tag : undefined,
              name: student.studentName,
              id: student.studentId,
              title,
              index,
              online: res.data.online,
              max,
              canEdit: true,
            }
          })
          .catch(err => console.log(err))
          .finally(() => (this.drawerLoading = false))
      } else {
        this.drawerInfo = {
          type,
          score: '-',
          level: '-',
          comment: '',
          content: 'Empty...',
          attachments: [],
          mark: type !== 'final' ? 'none' : undefined,
          name: student.studentName,
          id: student.studentId,
          title,
          index,
          online: false,
          max,
          canEdit: false,
        }
      }
    } else {
      const task = type === 'final' ? student : student[taskId]
      const rScore = type === 'final' ? task.finalGrade : task.manualScore
      this.drawerInfo = {
        type,
        score: score,
        customScore: rScore !== -1 ? rScore : undefined,
        level: type === 'final' ? task.gradeLevel : task.grade,
        name: student.studentName,
        id: student.studentId,
        title,
        index,
        gradePeriodId: type === 'final' ? undefined : Number(taskId.replace('s', '')),
        isLocked:
          type === 'final' ? false : this.columns.find(item => item.key === taskId).isLocked,
      }
    }
  }

  /**
   * 切换下一个学生
   */
  private async nextStudent(index): Promise<any> {
    if (this.operationAuths.includes('2086')) {
      const result = await this.saveScore()
      if (result !== 'success') return
    }
    while (this.realData[index] && !this.realData[index].isStudent) {
      index++
    }
    if (index === this.realData.length) return
    const student = this.realData[index]

    this.setDetail(
      this.drawerInfo.type === 'final'
        ? this.realData[index].finalScore
        : student?.[this.editTaskId].score,
      student,
      this.drawerInfo.type,
      this.editTaskId,
      index,
      false
    )
  }

  /**
   * 切换上一个学生
   */
  private async lastStudent(index): Promise<any> {
    if (this.operationAuths.includes('2086')) {
      const result = await this.saveScore()
      if (result !== 'success') return
    }
    while (this.realData[index] && !this.realData[index].isStudent) {
      index--
    }
    if (index === -1) return
    const student = this.realData[index]

    this.setDetail(
      this.drawerInfo.type === 'final'
        ? this.realData[index].finalScore
        : student?.[this.editTaskId].score,
      student,
      this.drawerInfo.type,
      this.editTaskId,
      index,
      false
    )
  }

  /**
   * 任务详情学生附件文件预览
   */
  private preview(file): void {
    const microsoft = ['.doc', '.xls', '.xlsx', '.docx', '.ppt', '.pptx']
    const normal = ['.pdf', '.png', '.jpg', '.jpeg', '.gif']
    const video = ['.mov', '.mp4', '.mp3', '.mp5']
    const suffixArray = microsoft.concat(normal, video)
    const index = file.resourceName.lastIndexOf('.')
    if (index === -1) {
      this.$aDownload(file.resourceUrl, this.createFileName(file.resourceName))
      return
    }
    const suffix = file.resourceName.slice(index).toLowerCase()
    if (suffixArray.includes(suffix)) {
      if (microsoft.includes(suffix) && file.resourceSize >= 10 * 1024 * 1024) {
        this.$aDownload(file.resourceUrl, this.createFileName(file.resourceName))
        return
      }
      this.docViewVis = true
      this.docUrl = microsoft.includes(suffix)
        ? `https://view.officeapps.live.com/op/view.aspx?src=${file.resourceUrl}`
        : file.resourceUrl
      this.docType = video.includes(suffix) ? 'video' : 'doc'
    } else {
      this.$aDownload(file.resourceUrl, this.createFileName(file.resourceName))
    }
  }

  private showRules(): void {
    this.rulesVisible = true
  }

  private showFSRules(): void {
    const courseId = this.type === 'subjectClass' ? this.courseId[1] : this.courseId
    TaskGradeController.queryFinalScoreRule(courseId).then(res => {
      this.fsRules = {
        ...res.data,
        error: false,
      }
      this.fsRulesDup = cloneDeep(this.fsRules)
      this.fsRulesVisible = true
    })
  }

  /**
   * 校验是不是第一个学生,用于任务详情面板判断显不显示上一个的按钮
   */
  private validateIsFirst(index): boolean {
    do {
      index--
      if (index === -1) {
        return true
      }
    } while (this.realData[index] && !this.realData[index].isStudent)
    return false
  }

  /**
   * 校验是不是最后一个学生,用于任务详情面板判断显不显示下一个的按钮
   */
  private validateIsLast(index): boolean {
    do {
      index++
      if (index === this.realData.length) {
        return true
      }
    } while (this.realData[index] && !this.realData[index].isStudent)
    return false
  }

  /**
   * 成绩录入时,当聚焦某个格子时显示输入框
   */
  private focus(index, col): any {
    if (!col.isTask) return
    // console.log(this.realData[index][col.dataIndex].canEdit)
    this.currentFocus = {
      row: index,
      col: col.key,
      canEdit: this.dataDup[index][col.dataIndex]?.canEdit,
    }
    this.$nextTick(() => {
      ;(this.$refs.scoreInput as any).focus()
    })
  }

  /**
   * 键盘按键事件,用于监听上下左右以切换学生输入框
   */
  private keydown(e): any {
    if (!Object.keys(this.currentFocus).length || ![37, 38, 39, 40].includes(e.keyCode)) return
    e.stopPropagation()
    let { row, col } = this.currentFocus
    let idx = this.columns.findIndex(column => column.key === col)
    switch (e.keyCode) {
      case 40:
        this.currentFocus = {
          col,
          row: row === this.dataDup.length - 1 ? row : (row += 1),
          canEdit: this.dataDup[row][col].canEdit,
        }
        break
      case 38:
        this.currentFocus = {
          col,
          row: row === 2 ? row : row - 1,
          canEdit: this.dataDup[row][col].canEdit,
        }
        break
      case 37:
        idx--
        while (idx > 0) {
          if (this.columns[idx].isTask) {
            col = this.columns[idx].key
            break
          }
          idx--
        }
        this.currentFocus = {
          row,
          col: col,
          canEdit: this.dataDup[row][col].canEdit,
        }
        break
      case 39:
        idx++
        while (idx <= this.columns.length - 1) {
          if (this.columns[idx].isTask) {
            col = this.columns[idx].key
            break
          }
          idx++
        }
        this.currentFocus = {
          row,
          col: col,
          canEdit: this.dataDup[row][col].canEdit,
        }
        break
    }
    this.$nextTick(() => {
      ;(this.$refs.scoreInput as any).focus()
      let idx = this.columns.slice(3).findIndex(column => column.key === col)
      const left = document.getElementsByClassName('ant-table-body')[0].scrollLeft

      if (left - idx * 180 > 100) {
        document.getElementsByClassName('ant-table-body')[0].scrollLeft = idx * 180
      }
      // document.activeElement?.scrollIntoView({behavior: 'smooth', block: 'end'})
    })
  }

  /**
   * 失焦时触发,保存当前修改的成绩
   */
  private onBlur(index, key, immediate = false): void {
    const cb = () => {
      let student = this.dataDup[index]
      const lastScore = this.data[index][key].score
      if (
        (student = this.changedStudent.find(student => student.studentId === student.studentId))
      ) {
        if (this.dataDup[index][key].score === lastScore) {
          delete student.taskGradesMap[this.dataDup[index][key].taskStudentId]
        } else {
          student.taskGradesMap[this.dataDup[index][key].taskStudentId] = this.dataDup[index][
            key
          ].score
        }
      } else {
        const tsId = this.dataDup[index][key].taskStudentId
        if (this.dataDup[index][key].score !== lastScore) {
          this.changedStudent.push({
            studentId: this.dataDup[index].studentId,
            taskGradesMap: {
              [tsId]: this.dataDup[index][key].score,
            },
            courseId: this.type === 'subjectClass' ? this.courseId[1] : this.courseId,
          })
        }
      }
      let { row, col } = this.currentFocus
      if (row === index && col === key) {
        this.currentFocus = {}
      }
    }
    immediate ? cb() : this.$nextTick(cb)
  }

  /**
   * 每个任务的最大分数值
   */
  private getMax(key): void {
    return key === 'finalScore'
      ? 100
      : (this.dynamicHeader.filter(item => item.taskId === key)[0] || {}).max
  }

  private toTaskDetail(task): void {
    this.$router.push({
      name: 'sAssignmentDetail',
      params: { id: task.taskId },
      query: { assignType: this.type, dateShow: 'true' },
    })
  }

  /**
   * 计算平均数和中位数
   */
  private getNotStudentData(data): any {
    const finalScore = data
      .map(item => (item.manual ? item.finalGrade : item.finalScore))
      .filter(item => item !== '--' && (item || item === 0))
      .sort((a, b) => a - b)
    let average = {}
    let middle = {}
    this.dynamicHeader.forEach(header => {
      let scores =
        header.taskType === 'semester'
          ? data
              .map(item => {
                let task = item[header.taskId]
                return task.manual ? task.manualScore : task.score
              })
              .filter(item => item !== '--' && (item || item === 0))
          : data
              .map(item => item[header.taskId].score)
              .filter(item => item !== '--' && (item || item === 0))
      if (!scores.length) {
        this.$set(average, header.taskId, {
          score: '--',
          type: header.taskType,
        })
        this.$set(middle, header.taskId, {
          score: '--',
          type: header.taskType,
        })
        return
      }
      scores.sort((a, b) => a - b)
      this.$set(average, header.taskId, {
        score: round(scores.reduce((current, total) => total + current, 0) / scores.length, 1),
        type: header.taskType,
      })
      this.$set(middle, header.taskId, {
        score: round(
          scores.length % 2 === 0
            ? (scores[scores.length / 2] + scores[scores.length / 2 - 1]) / 2
            : scores[Math.floor(scores.length / 2)],
          1
        ),
        type: header.taskType,
      })
    })
    return [
      {
        isStudent: false,
        studentName: '平均分 (Mean)',
        lastName: '平均分 (Mean)',
        studentId: 'not_student_' + 0,
        finalScore: finalScore.length
          ? round(finalScore.reduce((current, total) => total + current, 0) / finalScore.length, 1)
          : '--',
        ...average,
      },
      {
        isStudent: false,
        studentName: '中位数 (Median)',
        lastName: '中位数 (Median)',
        studentId: 'not_student_' + 1,
        finalScore: finalScore.length
          ? round(
              finalScore.length % 2 === 0
                ? (finalScore[finalScore.length / 2] + finalScore[finalScore.length / 2 - 1]) / 2
                : finalScore[Math.floor(finalScore.length / 2)],
              1
            )
          : '--',
        ...middle,
      },
    ]
  }

  private validateFS(): void {
    const { averageFactor, midFactor, endFactor } = this.fsRules
    this.fsRules.error = !isLegal(averageFactor) || !isLegal(midFactor) || !isLegal(endFactor)
  }
}
