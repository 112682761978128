



































































































































































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import filterGroup from '@/components/filterGroup'
import debounce from 'lodash/debounce'
import { i18n } from '@/i18n/i18n'
import {
  ClassMaterialController,
  DropDownController,
  TaskController,
} from '@/services/request.service'
import { toPage } from '@/utils/utils'
import flexTooltip from '@/components/FlexTooltip.vue'
import DetailModal from '@/components/DetailModal.vue'
import moment from 'moment'
import { createPagination } from '@/constant/constant'
import rulesEditor from './RulesEditor.vue'
import TextFold from '@/components/TextFold.vue'

const operations = {
  '1001': function(id) {
    return TaskController.readDetail(id)
  },
  '1002': function(id) {
    return ClassMaterialController.readDetail(id)
  },
}

@Component({
  components: {
    filterGroup,
    flexTooltip,
    DetailModal,
    rulesEditor,
    TextFold,
  },
})
export default class TaskAndData extends Vue {
  @Prop() private readonly type!: string
  @Prop() private readonly currentCourseFlag!: Boolean
  @Prop() private readonly schoolYear!: any
  @Prop() private readonly schoolYearId!: any

  @Prop() private readonly courseId!: Array<any> | number
  @Prop() private readonly courses!: Array<any>

  private courseLoading: any = false
  // type: 1001任务 1002资料
  private data: Array<any> = []
  private taskTypes: Array<any> = []
  private filters: any = {
    name: undefined,
    type: undefined,
    taskTypeId: undefined,
    taskStatus: [],
    scoreFlag: [],
    inTotal: [],
  }
  private loading: any = false
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private schoolYearList: any = []
  private detailVisible: any = false
  private materialInfo: any = {}
  private rulesVisible: any = false

  private readStatusDetails: any = {
    title: '',
    infoList: [],
  }
  private readStatusVis: boolean = false

  private get filterNum(): number {
    const filters = Object.values(this.filters) as Array<any>
    return filters
      .map(item =>
        ['string', 'number', 'undefined'].includes(typeof item) ? !!item : !!item.length
      )
      .filter(item => item).length
  }
  private updateData() {
    this.$emit('updateData')
  }
  private get columns(): any {
    return [
      {
        dataIndex: 'type',
        ellipsis: true,
        title: this.$t('common.type'),
        width: 130,
        scopedSlots: { customRender: 'type' },
      },
      {
        key: 'name',
        title: this.$t('common.name'),
        ellipsis: true,
        scopedSlots: { customRender: 'name' },
      },
      {
        key: 'status',
        title: this.$t('common.status'),
        ellipsis: true,
        scopedSlots: { customRender: 'status' },
      },
      {
        key: 'readStatus',
        title: this.$t('message.readStatus'),
        ellipsis: true,
        scopedSlots: { customRender: 'readStatus' },
      },
      {
        key: 'submit',
        title: this.$t('assignment.Submit'),
        ellipsis: true,
        width: 100,
        scopedSlots: { customRender: 'submit' },
      },
      {
        key: 'mark',
        title: this.$t('homework.mark'),
        ellipsis: true,
        width: 100,
        scopedSlots: { customRender: 'mark' },
      },
      {
        dataIndex: 'createTime',
        key: 'createTime',
        title: this.$t('assignment.createTime'),
        scopedSlots: { customRender: 'createTime' },
      },
      {
        key: 'operations',
        title: this.$t('common.operations'),
        scopedSlots: { customRender: 'operations' },
      },
    ]
  }

  private get locale(): any {
    return this.$store.state.locale
  }

  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }

  private get isHistoryYear(): boolean {
    if (this.schoolYear?.extraValue === '1102') {
      return true
    }
    return false
  }
  @Watch('schoolYearId', { immediate: true })
  public getSchoolYearId() {}
  private created(): void {
    this.getTaskTypes()
  }

  private getTaskTypes(): void {
    DropDownController.getTaskType()
      .then(res => {
        this.taskTypes = res.data
      })
      .catch(err => console.log(err))
  }

  private add(type): void {
    if (type === 'resource') {
      this.$router
        .push({
          name: 'materialEditor',
          query: {
            type: 'add',
            defaultClassId: this.type === 'subjectClass' ? this.courseId?.[1] : this.courseId,
            courseType: this.type,
          },
        })
        .catch(err => {})
    } else {
      this.$router.push({
        name: 'assignmentEditor',
        query: {
          type: 'add',
          defaultClassId: this.type === 'subjectClass' ? this.courseId?.[1] : this.courseId,
          assignType: this.type,
        },
      })
    }
  }

  private copy(record): void {
    if (record.type === '1002') {
      this.$router
        .push({
          name: 'materialEditor',
          query: {
            type: 'copyEdit',
            courseType: this.type,
            materialId: record.entityId,
            dateShow: record.editFlag,
          },
        })
        .catch(err => {})
    } else {
      this.$router.push({
        name: 'assignmentEditor',
        query: {
          type: 'copyEdit',
          assignType: this.type,
          id: record.entityId,
          dateShow: record.editFlag,
        },
      })
    }
  }

  private deleteItem(record): void {
    if (record.type === '1002') {
      ClassMaterialController.delete(record.entityId)
        .then(res => {
          this.$message.success(this.$tc('common.deleteSuccess'))
        })
        .catch(err => console.log(err))
        .finally(() => this.refresh())
    } else {
      TaskController.delete(record.entityId)
        .then(res => {
          this.$message.success(this.$tc('common.deleteSuccess'))
        })
        .catch(err => console.log(err))
        .finally(() => this.refresh())
    }
  }

  private edit(record): void {
    if (record.type === '1002') {
      this.$router
        .push({
          name: 'materialEditor',
          query: {
            type: 'edit',
            courseType: this.type,
            materialId: record.entityId,
            dateShow: record.editFlag,
          },
        })
        .catch(err => {})
    } else {
      this.$router.push({
        name: 'assignmentEditor',
        query: {
          type: 'edit',
          assignType: this.type,
          id: record.entityId,
          dateShow: record.editFlag,
        },
      })
    }
  }

  private fetchData = debounce(() => {
    this.getData()
  }, 500)

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    this.data = []
    const courseId = this.type === 'subjectClass' ? this.courseId[1] : this.courseId
    if (!courseId) return
    this.loading = true
    const { name, type, taskTypeId, taskStatus, scoreFlag, inTotal } = this.filters
    const request = {
      pageSize: page.pageSize,
      pageCurrent: page.current,
      courseId,
      schoolYearId: this.schoolYear?.key,
      name: name?.trim(),
      type,
      taskTypeId,
      taskStatus: taskStatus.length === 1 ? taskStatus[0] : undefined,
      scoreFlag: scoreFlag.length === 1 ? scoreFlag[0] : undefined,
      inTotal: inTotal.length === 1 ? inTotal[0] : undefined,
    }
    TaskController.getMergeList(request)
      .then(res => {
        this.data = res.data.items
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => console.log(err))
      .finally(() => (this.loading = false))
  }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination)
  }

  private judgeEdit(item): any {
    return item.editFlag && this.operationAuths.includes('2086')
  }

  private refresh(): void {
    this.getData(this.pagination)
  }

  private viewDetail(record): void {
    if (record.type === '1001') {
      this.$router.push({
        name: 'assignmentDetail',
        params: { id: record.entityId },
        query: { assignType: this.type, dateShow: record.editFlag },
      })
    } else {
      ClassMaterialController.detail(record.entityId)
        .then(res => {
          this.materialInfo = this.dealDetailData(res.data)
          this.detailVisible = true
        })
        .catch(err => console.log(err))
    }
  }

  private viewReadStatus(record): void {
    operations[record.type](record.entityId)
      .then(res => {
        this.readStatusDetails.title = this.$t('message.readDetail')
        this.readStatusDetails.infoList = this.dealReadStatus(res.data)
        this.readStatusVis = true
      })
      .catch(err => console.log(err))
  }

  private dealReadStatus(data): any {
    let readItem = {
        key: 'readed',
        label: this.$t('message.read'),
        value: '',
      },
      readStuListItem = {
        key: 'readStuList',
        label: this.$t('common.student'),
        value: '',
      },
      readParListItem = {
        key: 'readParList',
        label: this.$t('common.parent'),
        value: '',
      },
      unReadItem = {
        key: 'unReaded',
        label: this.$t('message.unread'),
        value: '',
      },
      unReadStuListItem = {
        key: 'unReadStuList',
        label: this.$t('common.student'),
        value: '',
      },
      unReadParListItem = {
        key: 'unReadParList',
        label: this.$t('common.parent'),
        value: '',
      }
    if (data) {
      readItem.value = `(${data.read.count})`
      readStuListItem.value = data.read.students
        .map(item => ((item.enName ? item.enName : '') + ' ' + (item.name ? item.name : '')).trim())
        .join('、')
      readParListItem.value = data.read.parents
        .map(item =>
          (
            (item.enName ? item.enName : '') +
            ' ' +
            (item.name ? item.name : '') +
            this.$t(`relation.${item.relationship}`)
          ).trim()
        )
        .join('、')
      unReadItem.value = `(${data.unRead.count})`
      unReadStuListItem.value = data.unRead.students
        .map(item => ((item.enName ? item.enName : '') + ' ' + (item.name ? item.name : '')).trim())
        .join('、')
      unReadParListItem.value = data.unRead.parents
        .map(item =>
          (
            (item.enName ? item.enName : '') +
            ' ' +
            (item.name ? item.name : '') +
            this.$t(`relation.${item.relationship}`)
          ).trim()
        )
        .join('、')
    }
    return [
      readItem,
      readStuListItem,
      readParListItem,
      unReadItem,
      unReadStuListItem,
      unReadParListItem,
    ]
  }

  private dealDetailData(data): any {
    const name = {
      key: 'name',
      label: this.$t('courseMaterial.name'),
      value: '',
    }
    const startDate = {
      key: 'startTime',
      label: this.$t('teaching.startDate'),
      value: '',
    }
    const course = {
      key: 'course',
      label: this.$t('common.subjectClass'),
      value: '',
    }
    const attachment = {
      key: 'attachment',
      label: this.$t('courseMaterial.attachment'),
      value: '',
      attachments: [],
    }
    const instruction = {
      key: 'instruction',
      label: this.$t('courseMaterial.instruction'),
      value: '',
      visRow: 6,
    }
    if (data) {
      name.value = data.name
      startDate.value = moment(data.startDate).format('YYYY/MM/DD HH:mm')
      course.value = data.courses.map(item => item.description).join(', ')
      instruction.value = data.remark
      attachment.attachments = data.resources.map(item => {
        return {
          uid: item.resourceId,
          url: item.resourceUrl,
          name: item.resourceName,
          status: 'done',
        }
      })
    }
    return { name, course, startDate, attachment, instruction }
  }

  private showRules(): void {
    this.rulesVisible = true
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to, from): void {
    if (from && from.meta.refresh === true) {
      this.refresh()
    }
  }

  private reset(): void {
    this.filters = {
      name: undefined,
      type: undefined,
      taskTypeId: undefined,
      taskStatus: [],
      scoreFlag: [],
      inTotal: [],
    }
    this.getData()
  }
}
