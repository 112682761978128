







































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import {
  CourseController,
  DropDownController,
  ClassMaterialController,
} from '@/services/request.service'
import moment from 'moment'
import { fileUploader } from '@/services/qiniu.service'
import { clearEmptyArray, guid } from '@/utils/utils'
import Upload from '@/components/Upload.vue'
const operations = {
  add: function(values) {
    return ClassMaterialController.add(values)
  },
  copyEdit: function(values) {
    return ClassMaterialController.add(values)
  },
  edit: function(values) {
    return ClassMaterialController.update(values)
  },
}
Component.registerHooks(['beforeRouteLeave'])
@Component({
  components: {
    Upload,
  },
})
export default class MaterialEditor extends Vue {
  private courses: Array<any> = []
  private courseIds: any = []
  private emptyCourse: boolean = false
  private materialEditor: any
  private uploading: boolean = false
  private canLeave: boolean = false
  private courseText: any = ''
  private formItemLayout: any = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
  }
  private classArrangeId: any = undefined
  private loading: boolean = false
  private moment = moment

  private get courseType(): any {
    return this.$route.query.courseType || 'subjectClass'
  }

  private get dateShow(): any {
    return this.$route.query.dateShow
  }

  private get materialId(): any {
    return this.$route.query.materialId
  }

  private get defaultClassId(): any {
    const classId = this.$route.query.defaultClassId as any
    return classId ? [classId * 1] : []
  }

  private get editType(): any {
    return this.$route.query.type || 'add'
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get formatSections(): any {
    return this.courses.map(item => {
      return {
        children: item.subOptions.map(child => ({
          key: child.key,
          title: this.locale === 'zh' ? child.value : child.enValue || child.value,
          value: child.key,
        })),
        title: `${item.value}-${item.enValue}`,
        key: `${item.enValue}_${item.extraValue}_${item.key}`,
        value: `${item.enValue}_${item.extraValue}_${item.key}`,
      }
    })
  }

  private get currentSchoolYear(): any {
    return this.$store.state.currentSchoolYear
  }

  private disabledStartDate(current): any {
    return (
      current &&
      (current < moment(this.currentSchoolYear.startTime).startOf('day') ||
        current > moment(this.currentSchoolYear.endTime).endOf('day'))
    )
  }

  private getDropDownInfo(): void {
    this.materialEditor.setFieldsValue({ classArrangeId: undefined })
    this.courses = []
    this.courseType === 'subjectClass'
      ? CourseController.getCoursesOptionsCascade()
          .then(res => {
            this.courses = clearEmptyArray(res.data)
            if (res.data.length && this.defaultClassId.length) {
              this.courseIds = this.defaultClassId
            }
          })
          .catch(err => {
            console.error(err)
          })
      : DropDownController.getCcaCourse()
          .then(res => {
            this.courses = res.data
            if (res.data.length && this.defaultClassId.length) {
              this.courseIds = this.defaultClassId
            }
          })
          .catch(err => console.log(err))
  }

  private submitMaterial(): void {
    this.loading = true
    this.materialEditor.validateFields((err: any, values: any) => {
      this.emptyCourse = !this.courseIds.length
      if (err || this.emptyCourse) {
        this.loading = false
        return
      } else {
        let condition = {
          classMaterialId: this.editType === 'edit' ? this.materialId : undefined,
          name: values.name,
          // classTime: values.classTime.valueOf(),
          courseIds: this.courseIds,
          remark: values.remark,
          startDate: values.startDate.valueOf(),
          resourceIds: values.attachments.map(attachment => attachment.uid),
        }

        operations[this.editType](condition).then(res => {
          if (!res.data || (res.data && res.data.success)) {
            this.canLeave = true
            this.$route.meta.refresh = true
            this.$message.success(this.$tc('common.saveSuccess'))
            this.$router.back()
          } else {
            this.$message.error(res.data.resMsg)
          }
        })
      }
    })
  }

  private beforeRouteLeave(to, from, next): void {
    if (!this.$store.state.forceBack) {
      if (!this.canLeave) {
        this.$confirm({
          title: this.$t('common.unsaveConfirm') as string,
          onOk: () => {
            next()
          },
          onCancel: () => {
            next(false)
          },
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }

  private cancel(): void {
    this.$router.back()
  }

  private beforeCreate(): void {
    this.materialEditor = this.$form.createForm(this)
  }

  private created(): void {
    this.getDropDownInfo()
  }

  private disabledDate(current): any {
    let currentSchoolYear = this.$store.state.currentSchoolYear
    if (!Object.keys(currentSchoolYear).length) return false
    return (
      current &&
      (current < moment(currentSchoolYear.startTime).startOf('day') ||
        current > moment(currentSchoolYear.endTime).startOf('day'))
    )
  }

  private filterData(inputValue, path) {
    return path.some(option => option.label.indexOf(inputValue) > -1)
  }

  private getMaterialInfo() {
    if (!this.materialId) return
    ClassMaterialController.detail(this.materialId)
      .then(res => {
        this.courseIds =
          this.editType === 'copyEdit' ? [] : res.data.courses.map(item => item.courseId)
        let data = {
          // classTime: moment(res.data.classTime),
          name: res.data.name,
          remark: res.data.remark,
          // startDate: moment(res.data.startDate),
          startDate: this.dateShow ? moment(res.data.startDate) : null,
          attachments: res.data.resources.map(item => {
            return {
              uid: item.resourceId,
              url: item.resourceUrl,
              name: item.resourceName,
              status: 'done',
            }
          }),
        }
        this.materialEditor.setFieldsValue(data)
        // this.classArrangeId = res.data.classArrangeId
      })
      .catch(err => console.log(err))
  }

  private onCourseChange(value, label, extra) {
    this.courseIds = value
    this.emptyCourse = !this.courseIds.length
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    if (to.hash) {
      this.$router.replace({ name: 'teaching', params: to.params })
      return
    }
    to.meta.routerName = to.query.type !== 'add' ? 'editMaterial' : 'addMaterial'
    if (to.query.type !== 'add') {
      this.getMaterialInfo()
    }
  }
}
