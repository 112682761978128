var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{staticClass:"assignment-editor",attrs:{"layout":"horizontal","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"form":_vm.assignmentEditor,"autocomplete":"nope"}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('common.name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'name',
        {
          rules: [
            {
              required: true,
              message: _vm.$t('placeholder.enterName'),
            },
            {
              max: 50,
              message: _vm.$t('common.textLimit50'),
            } ],
        } ]),expression:"[\n        'name',\n        {\n          rules: [\n            {\n              required: true,\n              message: $t('placeholder.enterName'),\n            },\n            {\n              max: 50,\n              message: $t('common.textLimit50'),\n            },\n          ],\n        },\n      ]"}],attrs:{"placeholder":_vm.$t('placeholder.enterName')}})],1),(_vm.courseType === 'subjectClass')?_c('a-form-item',{staticClass:"form-item",attrs:{"help":_vm.emptyCourse ? _vm.$t('tips.selectSubjectClass') : '',"label":_vm.$t("common.subjectClass"),"validateStatus":_vm.emptyCourse ? 'error' : '',"required":""}},[_c('a-tree-select',{staticClass:"filter-input",attrs:{"tree-data":_vm.formatSections,"dropdownClassName":"drop-down-tree","tree-checkable":"","search-placeholder":_vm.$t('common.select'),"placeholder":_vm.$t('common.select'),"getPopupContainer":function () { return _vm.$document.body; },"searchValue":'',"allowClear":"","disabled":_vm.type === 'edit'},on:{"change":_vm.onCourseChange},model:{value:(_vm.treeSelectCourses),callback:function ($$v) {_vm.treeSelectCourses=$$v},expression:"treeSelectCourses"}})],1):_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t("common.CCAClass")}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'courseIds',
        {
          rules: [
            {
              required: true,
              message: _vm.$t('tips.selectLifeBlock'),
            } ],
        } ]),expression:"[\n        'courseIds',\n        {\n          rules: [\n            {\n              required: true,\n              message: $t('tips.selectLifeBlock'),\n            },\n          ],\n        },\n      ]"}],staticClass:"filter-input",attrs:{"mode":"multiple","placeholder":_vm.$t('common.select'),"disabled":_vm.type === 'edit',"optionFilterProp":"children"},on:{"change":_vm.onCourseChange}},_vm._l((_vm.courses),function(item){return _c('a-select-option',{key:item.key},[_vm._v(_vm._s(_vm.locale === 'zh' ? item.value : item.enValue || item.value))])}),1)],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t("common.type")}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'type',
        {
          rules: [
            {
              required: true,
              message: _vm.$t('tips.selectType'),
            },
            {
              validator: _vm.validateExistType,
              from: 'type',
            } ],
        } ]),expression:"[\n        'type',\n        {\n          rules: [\n            {\n              required: true,\n              message: $t('tips.selectType'),\n            },\n            {\n              validator: validateExistType,\n              from: 'type',\n            },\n          ],\n        },\n      ]"}],staticClass:"filter-input",attrs:{"placeholder":_vm.$t('common.select')}},_vm._l((_vm.typeList),function(item){return _c('a-select-option',{key:item.taskTypeId},[_vm._v(_vm._s(_vm.locale === 'zh' ? item.name : item.enName || item.name))])}),1)],1),_c('a-form-item',{staticClass:"form-item",attrs:{"help":_vm.descriptionErr,"label":_vm.$t('common.description'),"validateStatus":_vm.descriptionErr ? 'error' : '',"required":""}},[_c('simditor-vue',{ref:"aSim",attrs:{"placeholder":_vm.$t('placeholder.enterDescription')},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('common.attachment')}},[_c('upload',{directives:[{name:"decorator",rawName:"v-decorator",value:(['attachments', { initialValue: [] }]),expression:"['attachments', { initialValue: [] }]"}],on:{"loadingChange":function (status) { return (_vm.uploading = status); }}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('assignment.startDate')}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'startDate',
        {
          rules: [
            {
              required: true,
              message: _vm.$t('common.select'),
            },
            {
              validator: _vm.validateAssignTime,
              from: 'startDate',
            } ],
        } ]),expression:"[\n        'startDate',\n        {\n          rules: [\n            {\n              required: true,\n              message: $t('common.select'),\n            },\n            {\n              validator: validateAssignTime,\n              from: 'startDate',\n            },\n          ],\n        },\n      ]"}],staticStyle:{"width":"100%"},attrs:{"disabledDate":_vm.disabledStartDate,"showTime":{
        format: 'HH:mm',
      },"format":"YYYY-MM-DD HH:mm"},on:{"change":function($event){return _vm.onAssignTimeChange('startDate')}}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('assignment.deadline')}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'deadline',
        {
          rules: [
            {
              required: true,
              message: _vm.$t('common.select'),
            },
            {
              validator: _vm.validateAssignTime,
              from: 'deadline',
            } ],
        } ]),expression:"[\n        'deadline',\n        {\n          rules: [\n            {\n              required: true,\n              message: $t('common.select'),\n            },\n            {\n              validator: validateAssignTime,\n              from: 'deadline',\n            },\n          ],\n        },\n      ]"}],staticStyle:{"width":"100%"},attrs:{"disabledDate":_vm.disabledStartDate,"showTime":{
        format: 'HH:mm',
      },"format":"YYYY-MM-DD HH:mm"},on:{"change":function($event){return _vm.onAssignTimeChange('deadline')}}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('assignment.assignmentType')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'online',
        {
          rules: [
            {
              required: true,
              message: _vm.$t('tips.selectAssignmentType'),
            } ],
          initialValue: _vm.online,
        } ]),expression:"[\n        'online',\n        {\n          rules: [\n            {\n              required: true,\n              message: $t('tips.selectAssignmentType'),\n            },\n          ],\n          initialValue: online,\n        },\n      ]"}],staticClass:"filter-input",attrs:{"disabled":!_vm.editable},on:{"change":_vm.changeType}},[_c('a-select-option',{key:"true"},[_vm._v(_vm._s(_vm.$t('assignment.online2')))]),_c('a-select-option',{key:"false"},[_vm._v(_vm._s(_vm.$t('assignment.offline')))])],1)],1),(_vm.online === 'true')?_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('assignment.handInAfterDead')}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'overDeadline',
        {
          rules: [
            {
              required: true,
              message: _vm.$t('tips.selectHandInOverDeadline'),
            } ],
          initialValue: false,
        } ]),expression:"[\n        'overDeadline',\n        {\n          rules: [\n            {\n              required: true,\n              message: $t('tips.selectHandInOverDeadline'),\n            },\n          ],\n          initialValue: false,\n        },\n      ]"}]},[_c('a-radio',{attrs:{"value":true}},[_vm._v(_vm._s(_vm.$t('assignment.allow')))]),_c('a-radio',{attrs:{"value":false}},[_vm._v(_vm._s(_vm.$t('assignment.disallow')))])],1)],1):_vm._e(),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('assignment.scoreAllow')}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'scoreFlag',
        {
          rules: [
            {
              required: true,
            } ],
          initialValue: true,
        } ]),expression:"[\n        'scoreFlag',\n        {\n          rules: [\n            {\n              required: true,\n            },\n          ],\n          initialValue: true,\n        },\n      ]"}],staticStyle:{"flex-shrink":"0"},on:{"change":function($event){_vm.scoreAllow = !_vm.scoreAllow}}},[_c('a-radio',{attrs:{"value":true}},[_vm._v(_vm._s(_vm.$t('assignment.allow')))]),_c('a-radio',{attrs:{"value":false}},[_vm._v(_vm._s(_vm.$t('assignment.disallow')))])],1),_c('div',{staticClass:"tips-container"},[_c('a-icon',{attrs:{"type":"info-circle"}}),_c('span',[_vm._v(_vm._s(_vm.$t('assignment.allowTips')))])],1)],1),(_vm.scoreAllow)?[_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('assignment.topScore'),"required":""}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'topScore',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('tips.enterTopScore'),
              } ],
          } ]),expression:"[\n          'topScore',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('tips.enterTopScore'),\n              },\n            ],\n          },\n        ]"}],attrs:{"min":1,"precision":0}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('assignment.totalIncluded')}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'inTotal',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('tips.selectTotalIncluded'),
              } ],
            initialValue: true,
          } ]),expression:"[\n          'inTotal',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('tips.selectTotalIncluded'),\n              },\n            ],\n            initialValue: true,\n          },\n        ]"}]},[_c('a-radio',{attrs:{"value":true}},[_vm._v(_vm._s(_vm.$t('common.true')))]),_c('a-radio',{attrs:{"value":false}},[_vm._v(_vm._s(_vm.$t('common.false')))])],1)],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('assignment.publicScore')}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'publicFlag',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('tips.select'),
              } ],
            initialValue: true,
          } ]),expression:"[\n          'publicFlag',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('tips.select'),\n              },\n            ],\n            initialValue: true,\n          },\n        ]"}]},[_c('a-radio',{attrs:{"value":true}},[_vm._v(_vm._s(_vm.$t('common.true')))]),_c('a-radio',{attrs:{"value":false}},[_vm._v(_vm._s(_vm.$t('common.false')))])],1)],1)]:_vm._e(),_c('a-form-item',{attrs:{"label":" ","colon":false}},[_c('a-button',{attrs:{"type":"primary","disabled":_vm.uploading,"loading":_vm.loading},on:{"click":_vm.confirm}},[_vm._v(_vm._s(_vm.$t('common.confirm')))]),_c('a-button',{on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('common.cancel')))])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }