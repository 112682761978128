var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{staticClass:"meeting-editor",attrs:{"layout":"horizontal","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"form":_vm.materialEditor,"autocomplete":"nope"}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('courseMaterial.name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'name',
        {
          rules: [
            {
              required: true,
              message: _vm.$t('courseMaterial.enterName'),
            },
            {
              max: 50,
              message: _vm.$t('common.textLimit50'),
            } ],
        } ]),expression:"[\n        'name',\n        {\n          rules: [\n            {\n              required: true,\n              message: $t('courseMaterial.enterName'),\n            },\n            {\n              max: 50,\n              message: $t('common.textLimit50'),\n            },\n          ],\n        },\n      ]"}],attrs:{"placeholder":_vm.$t('courseMaterial.enterName')}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t(("courseMaterial." + _vm.courseType)),"help":_vm.emptyCourse
        ? _vm.$t(("tips.select" + (_vm.courseType === 'subjectClass' ? 'SubjectClass' : 'LifeBlock')))
        : '',"validateStatus":_vm.emptyCourse ? 'error' : '',"required":""}},[(_vm.courseType === 'subjectClass')?_c('a-tree-select',{staticClass:"filter-input",attrs:{"tree-data":_vm.formatSections,"dropdownClassName":"drop-down-tree","tree-checkable":"","search-placeholder":_vm.$t('common.select'),"placeholder":_vm.$t('common.select'),"getPopupContainer":function () { return _vm.$document.body; },"searchValue":'',"allowClear":""},on:{"change":_vm.onCourseChange},model:{value:(_vm.courseIds),callback:function ($$v) {_vm.courseIds=$$v},expression:"courseIds"}}):_c('a-select',{staticClass:"filter-input",attrs:{"mode":"multiple","placeholder":_vm.$t('common.select'),"disabled":_vm.editType === 'edit',"optionFilterProp":"children"},on:{"change":_vm.onCourseChange},model:{value:(_vm.courseIds),callback:function ($$v) {_vm.courseIds=$$v},expression:"courseIds"}},_vm._l((_vm.courses),function(item){return _c('a-select-option',{key:item.key},[_vm._v(_vm._s(_vm.locale === 'zh' ? item.value : item.enValue || item.value))])}),1)],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('assignment.startDate')}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'startDate',
        {
          rules: [
            {
              required: true,
              message: _vm.$t('common.select'),
            } ],
        } ]),expression:"[\n        'startDate',\n        {\n          rules: [\n            {\n              required: true,\n              message: $t('common.select'),\n            },\n          ],\n        },\n      ]"}],staticStyle:{"width":"100%"},attrs:{"disabledDate":_vm.disabledStartDate,"showTime":{
        format: 'HH:mm',
      },"format":"YYYY-MM-DD HH:mm"}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('message.attachments'),"required":""}},[_c('upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'attachments',
        {
          initialValue: [],
          rules: [
            {
              required: true,
              message: _vm.$t('courseMaterial.uploadMaterial'),
            } ],
        } ]),expression:"[\n        'attachments',\n        {\n          initialValue: [],\n          rules: [\n            {\n              required: true,\n              message: $t('courseMaterial.uploadMaterial'),\n            },\n          ],\n        },\n      ]"}],on:{"loadingChange":function (status) { return (_vm.uploading = status); }}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('courseMaterial.instruction')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'remark',
        {
          rules: [
            {
              max: 2000,
              message: _vm.$t('common.textLimit2000'),
            } ],
        } ]),expression:"[\n        'remark',\n        {\n          rules: [\n            {\n              max: 2000,\n              message: $t('common.textLimit2000'),\n            },\n          ],\n        },\n      ]"}],attrs:{"autoSize":{
        minRows: 4,
        maxRows: 8,
      },"placeholder":_vm.$t('courseMaterial.instruction')}})],1),_c('a-form-item',{attrs:{"label":" ","colon":false}},[_c('a-button',{attrs:{"type":"primary","loading":_vm.loading,"disabled":_vm.uploading},on:{"click":_vm.submitMaterial}},[_vm._v(_vm._s(_vm.$t('common.publish')))]),_c('a-button',{on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('common.cancel')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }